export const mainMenu = {
    "shop": {
        "variation1": [
            {
                "title": "Fullwidth Banner",
                "url": "/shop"
            },
            {
                "title": "Boxed Slider Banner",
                "url": "/shop/boxed-slider"
            },
            {
                "title": "Boxed Image Banner",
                "url": "/shop/boxed-image"
            },
            {
                "title": "Left Sidebar",
                "url": "/shop"
            },
            {
                "title": "Right Sidebar",
                "url": "/shop/right-sidebar",
                "hot": true
            },
            {
                "title": "Off-Canvas Filter",
                "url": "/shop/off-canvas"
            },
            {
                "title": "Horizontal Filter1",
                "url": "/shop/horizontal-filter-1"
            },
            {
                "title": "Horizontal Filter2",
                "url": "/shop/horizontal-filter-2"
            }
        ],
        "variation2": [
            {
                "title": "List Types",
                "url": "/shop/list"
            },
            {
                "title": "Ajax Infinite Scroll",
                "url": "/shop/infinite-scroll"
            },
            {
                "title": "3 Columns Products",
                "url": "/shop/3cols",
                "new": true
            },
            {
                "title": "4 Columns Products",
                "url": "/shop/4cols"
            },
            {
                "title": "5 Columns Products",
                "url": "/shop/5cols"
            },
            {
                "title": "6 Columns Products",
                "url": "/shop/6cols"
            },
            {
                "title": "7 Columns Products",
                "url": "/shop/7cols"
            },
            {
                "title": "8 Columns Products",
                "url": "/shop/8cols"
            }
        ]
    },
    "product": {
        "pages": [
            {
                "title": "Simple Product",
                "url": "/product/default/casual-spring-blue-shoes"
            },
            {
                "title": "Variable Product",
                "url": "/product/default/basketball-sports-blue-shoes"
            },
            {
                "title": "Sale Product",
                "url": "/product/default/brown-women-casual-hand-bag"
            },
            {
                "title": "Feature & On Sale",
                "url": "/product/default/men-black-sports-shoes"
            },
            {
                "title": "With Custom Tab",
                "url": "/product/custom-tab/casual-spring-blue-shoes"
            },
            {
                "title": "With Left Sidebar",
                "url": "/product/left-sidebar/circled-ultimate-3-d-speaker"
            },
            {
                "title": "With Right Sidebar",
                "url": "/product/right-sidebar/men-black-sports-watch"
            },
            {
                "title": "Add Cart Sticky",
                "url": "/product/sticky-cart/casual-spring-blue-shoes",
                "hot": true
            }
        ],
        "layout": [
            {
                "title": "Extended Layout",
                "url": "/product/extended/basketball-sports-blue-shoes",
                "new": true
            },
            {
                "title": "Grid Image",
                "url": "/product/grid/basketball-sports-blue-shoes"
            },
            {
                "title": "Full Width Layout",
                "url": "/product/full-width/basketball-sports-blue-shoes"
            },
            {
                "title": "Sticky Info",
                "url": "/product/sticky-info/basketball-sports-blue-shoes"
            },
            {
                "title": "Left & Right Sticky",
                "url": "/product/sticky-both/basketball-sports-blue-shoes"
            },
            {
                "title": "Transparent Image",
                "url": "/product/transparent/men-belt-double-set"
            },
            {
                "title": "Center Vertical",
                "url": "/product/center/basketball-sports-blue-shoes"
            }
        ]
    },
    "other": [
        {
            "title": "label.login",
            "url": "/login",
            "authenticated": false
        },
        {
            "title": "message.forgot_password",
            "url": "/forgot-password",
            "authenticated": false
        }
    ],
    "solutions": [
        {
            "title": "message.bank_and_finance",
            "url": "/bank-finance",
        },
        {
            "title": "label.residential",
            "url": "/residential",
        },
        {
            "title": "label.restaurant",
            "url": "/restaurant",
        },
        {
            "title": "label.hotels",
            "url": "/hotel",
        },
        {
            "title": "label.traffic",
            "url": "/traffic",
        },
        {
            "title": "label.seaport",
            "url": "/seaport",
        },
        {
            "title": "label.bus",
            "url": "/bus",
        },
        {
            "title": "message.entrance_and_exit",
            "url": "/entrance-exit",
        },
        {
            "title": "label.parking",
            "url": "/parking",
        },
        {
            "title": "label.airport",
            "url": "/airport",
        },
        {
            "title": "message.electric_station",
            "url": "/electric",
        },
        {
            "title": "message.gas_station",
            "url": "/gas-station",
        },
        {
            "title": "message.energy_and_oil",
            "url": "/energy-oil",
        }
    ]
}