import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from '../../common/ALink';
import {connect} from "react-redux";
import usePrice from "../../../utils/hooks/usePrice";

function ProductThree ( props ) {
    const { c } = usePrice();
    const { adClass = "", link = "default", product, user } = props;

    return (
        <div className={ `product-default media-with-lazy left-details product-widget ${adClass}` }>
            <figure>
                <ALink href={ `/product/${product.id}` } scroll={ true } >
                    <div className="lazy-overlay"></div>

                    <LazyLoadImage
                        alt="product"
                        src={ process.env.REACT_APP_PUBLIC_ASSET_URI + product.images[ 0 ].url }
                        threshold={ 500 }
                        effect="black and white"
                        width="100%"
                    />
                    {
                        product.images.length >= 2 ?
                            <LazyLoadImage
                                alt="product"
                                src={ process.env.REACT_APP_PUBLIC_ASSET_URI + product.images[ 1 ].url }
                                threshold={ 500 }
                                effect="black and white"
                                wrapperClassName="product-image-hover"
                            />
                            : ""
                    }
                </ALink>
            </figure>

            <div className="product-details">
                <h3 className="product-title">
                    <ALink href={ `/product/${product.id}` } scroll={ true }>{ product.name }</ALink>
                </h3>

                {user && product.price > 0 && <div className="price-box">
                    {
                        <span className="product-price">{c(product.price)}</span>
                    }
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect( mapStateToProps, mapDispatchToProps )( ProductThree );