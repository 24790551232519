import ALink from "../components/common/ALink";
import {useEffect} from "react";
import useTranslation from "../utils/hooks/useTranslation";

export default function ForgotPassword() {
    const { t } = useTranslation();
    useEffect( () => {
        let offset = document.querySelector( 'body' ).getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout( () => {
            window.scrollTo( { top: offset, behavior: 'smooth' } );
        }, 50 );

    }, [] )

    return (
        <main className="main">
            <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><ALink href="/">{t("label.home")}</ALink></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t("message.my_account")}
								</li>
                            </ol>
                        </div>
                    </nav>

                    <h1>{t("message.my_account")}</h1>
                </div>
            </div>

            <div className="container reset-password-container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="feature-box border-top-primary">
                            <div className="feature-box-content">
                                <form className="mb-0" action="#">
                                    <p>
                                        {t("message.lost_your_password")}
									</p>
                                    <div className="form-group mb-0">
                                        <label htmlFor="reset-email" className="font-weight-normal">{t("label.email")}</label>
                                        <input type="email" className="form-control" id="reset-email" name="reset-email"
                                            required />
                                    </div>

                                    <div className="form-footer mb-0">
                                        <ALink href="/login">{t("message.go_to_login_page")}</ALink>

                                        <button type="submit"
                                            className="btn btn-md btn-primary form-footer-right font-weight-normal text-transform-none mr-0">
                                            Reset Password
										</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}