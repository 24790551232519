import {Route, Routes} from "react-router-dom";
import {publicRoutes} from "./routes/";
import "./assets/sass/style.scss";
import Layout from './components/layout';
import {connect} from "react-redux";
import {getCurrentUser} from "./store/auth/actions";
import {useEffect, useState} from "react";
import {getCategoryList} from "./store/categories/actions";
import {getFeaturedProductList, getLatestProductList, getProductList} from "./store/products/actions";
import {getSliderData} from "./store/slider/actions";
import {Helmet} from "react-helmet";
import {getBlogList} from "./store/blog/actions";
import {partners} from "./utils/data/partners";
import {getPartnersList} from "./store/partners/actions";
import useEffectOnce from "./utils/hooks/useEffectOnce";
import PubNub from "pubnub";
import config from "./config";
import PubnubApp from "./components/pubnub";
import {PubNubProvider} from "pubnub-react";
import {getCurrency} from "./store/currency/actions";

const pubnub = new PubNub({
    publishKey: config.data.pubnub_publish_key,
    subscribeKey: config.data.pubnub_subscribe_key,
    uuid: "user-id"
});

function App({
                 auth,
                 getCurrentUser,
                 getSliderData,
                 getCategoryList,
                 getFeaturedProductList,
                 getLatestProductList,
                 sliderLoading,
                 getBlogList,
                 getPartnersList,
                 getCurrency
             }) {
    const [loading, setLoading] = useState(false);

    useEffectOnce(() => {
        getSliderData();
        getCategoryList();
        getPartnersList(partners.list);
        getBlogList();

        if (auth.token && auth.user === null) {
            getCurrentUser();
        } else {
            getFeaturedProductList();
            getLatestProductList();
        }

        getCategoryList();
        getSliderData();
        getBlogList();
        getPartnersList();
        getCurrency();
    })

    useEffect(() => {
        setLoading(sliderLoading)
    }, [sliderLoading])

    if (loading) {
        return (
            <div className="loading-overlay">
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        )
    }

    const renderHeaderMeta = () => {
        return (
            <Helmet>
                <title>SSD - Security Solution & Distribution!</title>
            </Helmet>
        )
    }

    return (
        <PubNubProvider client={pubnub}>
            <div className="App">
                {auth.user && <PubnubApp user={auth.user} />}
                {renderHeaderMeta()}
                <Routes>
                    <Route element={<Layout/>}>
                        {publicRoutes.map((route, idx) => (
                            <Route path={route.path} element={route.component} key={idx}>
                                {route.children?.map((childRoute, childIdx) => (
                                    <Route path={childRoute.path} element={childRoute.component} key={childIdx}/>
                                ))}
                            </Route>
                        ))}
                    </Route>
                </Routes>
            </div>
        </PubNubProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        sliderLoading: state.slider.loading,
    }
}

const mapDispatchToProps = {
    getCurrentUser,
    getSliderData,
    getCategoryList,
    getProductList,
    getFeaturedProductList,
    getLatestProductList,
    getBlogList,
    getPartnersList,
    getCurrency
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
