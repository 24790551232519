import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'

// Import Custom Component
import ALink from '../ALink';

// Import Utils
import { getCartTotal } from '../../../utils';
import {
    changeProductQuantity,
    removeProductFromCart, saveCart
} from "../../../store/cart/actions";
import {connect} from "react-redux";
import usePrice from "../../../utils/hooks/usePrice";
import useTranslation from "../../../utils/hooks/useTranslation";
import Qty from "../../partials/product/qty";

function CartMenu (props) {
    const { t } = useTranslation();
    const { c } = usePrice();
    const { cartItems, changeProductQuantity, removeProductFromCart, saveCart } = props;
    const location = useLocation();

    useEffect(()=> {
        cartClose()
    }, [location]);

    function toggleCart ( e ) {
        e.preventDefault();
        document.querySelector( 'body' ).classList.toggle( 'cart-opened' );
    }

    function cartClose () {
        document.querySelector( 'body' ).classList.contains( 'cart-opened' ) && document.querySelector( 'body' ).classList.remove( 'cart-opened' );
    }

    function getQtyTotal ( items ) {
        let total = 0;
        if ( items ) {
            for ( let i = 0; i < items.length; i++ ) {
                total += parseInt( items[ i ].quantity, 10 );
            }
        }
        return total;
    }

    function removeFromCart ( e, productId ) {
        e.preventDefault();
        removeProductFromCart( productId );
        saveCart();
    }

    return (
        <div className="dropdown cart-dropdown">
            <a href="#" title="Cart" className="dropdown-toggle dropdown-arrow cart-toggle" onClick={ toggleCart }>
                <i className="minicart-icon"></i>
                <span className="cart-count badge-circle">{ getQtyTotal( cartItems ) }</span>
            </a>

            <div className="cart-overlay" onClick={ cartClose }></div>

            <div className="dropdown-menu mobile-cart">
                <a href="#" title="Close (Esc)" className="btn-close" onClick={ e => { cartClose(); e.preventDefault(); } }>×</a>

                <div className="dropdownmenu-wrapper">
                    <div className="dropdown-cart-header">{t("label.cart")}</div>

                    {
                        cartItems.length > 0 ?
                            <>
                                <div className="dropdown-cart-products">
                                    {
                                        cartItems.map( ( cart, index ) => (
                                            <div className="product d-flex flex-column" key={ "cartItems" + index }>
                                                <div className={"d-flex justify-content-between w-100"}>
                                                    <div className="product-details">
                                                        <h2 className="product-title">
                                                            <ALink href={ `/product/${cart.id}` } scroll={ true }>{ cart.name }</ALink>
                                                        </h2>

                                                        <span className="cart-product-info">
                                                        <span className="cart-product-qty">{ cart.quantity }</span> × { c(cart.price) }
                                                    </span>
                                                    </div>

                                                    <figure className="product-image-container">
                                                        <ALink href={ `/product/${cart.id}` } scroll={ true } className="product-image">
                                                            <img src={ process.env.REACT_APP_PUBLIC_ASSET_URI + cart.images[ 0 ].url } width="78" height="78" alt="product" />
                                                        </ALink>
                                                        <a href="#" className="btn-remove icon-cancel" title="Remove Product" onClick={ e => { removeFromCart( e, cart.id ); } }></a>
                                                    </figure>
                                                </div>
                                                <div>
                                                    <Qty value={ cart.quantity } max={ cart.maxQuantity } onChangeQty={ qty => {
                                                        changeProductQuantity(index, qty);
                                                        saveCart();
                                                    } } />
                                                </div>
                                            </div>
                                        ) )
                                    }
                                </div>

                                <div className="dropdown-cart-total">
                                    <span className={"text-uppercase"}>{t("label.total")}:</span>

                                    <span className="cart-total-price float-right">{ c(getCartTotal( cartItems )) }</span>
                                </div>

                                <div className="dropdown-cart-action">
                                    <a href={"https://ssd.al/admin/order/new"} className="btn btn-dark btn-block text-white">{t("message.complete_order")}</a>
                                </div>
                            </>
                            :
                            <p className="pt-3 mt-2">{t("message.no_products_in_the_cart")}</p>
                    }
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        cartItems: state.cart.items,
    }
}

const mapDispatchToProps = {
    removeProductFromCart,
    changeProductQuantity,
    saveCart,
}

export default connect( mapStateToProps, mapDispatchToProps )( CartMenu );