import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import Custom Component
import HomeSection from "../components/partials/home/home-section";
import InfoSection from "../components/partials/home/info-section";
import FeaturedCollection from "../components/partials/home/featured-collection";
import NewCollection from "../components/partials/home/new-collection";
import SaleBanner from "../components/partials/home/sale-banner";
import CategorySection from "../components/partials/home/category-section";
import PromoSection from "../components/partials/home/promo-section";
import BrandSection from "../components/partials/home/brand-section";
import BlogSection from '../components/partials/home/blog-section';
import SolutionsSection from "../components/partials/home/solutions-section";
import {solutions} from "../utils/data/solutions";

function Home({featuredProducts, latestProducts, blog}) {
    const [loading, setLoading] = useState(false);
    const featured = featuredProducts.list;
    const latest = latestProducts.list;

    useEffect(() => {
        let offset = document.querySelector('body').getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout(() => {
            window.scrollTo({top: offset, behavior: 'smooth'});
        }, 50);

    }, [])

    useEffect(() => {
        setLoading(featuredProducts.loading || latestProducts.loading);
    }, [featuredProducts.loading, latestProducts.loading])

    if (featuredProducts.error) {
        return <div>{featuredProducts.error?.message}</div>
    }

    return (
        <>
            <main className={`skeleton-body skel-shop-products ${loading ? '' : 'loaded'}`}>
                <HomeSection/>

                <div className="container">
                    <InfoSection/>
                    {/*<BannerSection />*/}
                </div>

                <FeaturedCollection products={featured} loading={featuredProducts.loading}/>

                <NewCollection products={latest} loading={latestProducts.loading}/>

                <div className="container">
                    {/*<SaleBanner/>*/}
                    <CategorySection/>
                </div>

                {/*<FeatureBoxSection />*/}

                {/*<PromoSection/>*/}

                <BlogSection blog={blog.list} loading={blog.loading}/>

                <SolutionsSection solution={solutions}/>

                <div className="container">
                    <BrandSection/>

                    <hr className="mt-0 mb-0"/>
                </div>

                {/*<ProductWidgetContainer featured={ featured } latest={ latest } bestSelling={ bestSelling } topRated={ topRated } loading={ loading } />*/}
                {/*<ProductWidgetContainer featured={ featured } latest={ latest } loading={ loading } />*/}
            </main>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        featuredProducts: state.products.featured,
        latestProducts: state.products.latest,
        blog: state.blogs,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Home);