import {useEffect} from "react";
import useTranslation from "../utils/hooks/useTranslation";
import BusinessRegistrationForm from "../forms/businessRegistrationForm";
import {Helmet} from "react-helmet";


function B2B() {
    const { t } = useTranslation();
    useEffect( () => {
        let offset = document.querySelector( 'body' ).getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout( () => {
            window.scrollTo( { top: offset, behavior: 'smooth' } );
        }, 50 );

    }, [] )

    return (
        <>
            <Helmet>
                <title>SSD - {t("label.b2b")}</title>
            </Helmet>
            <main className="main business-register-main">
                <div className="container login-container business-register-container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="row justify-content-center">
                                <div>
                                    <div className="heading mb-1">
                                        <h2 className="title">{t("message.be_our_partner").toUpperCase()}</h2>
                                    </div>

                                    <BusinessRegistrationForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}


export default B2B;