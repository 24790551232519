import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import ALink from '../../components/common/ALink';
import ShopBanner from '../../components/partials/shop/shop-banner';
import ShopSidebarOne from '../../components/partials/shop/sidebar/shop-sidebar-one';
import Pagination from '../../components/features/pagination';
import ProductsGrid from '../../components/partials/products-collection/product-grid';
import {connect} from "react-redux";
import {
    decrementProductPage,
    getProductList,
    incrementProductPage, setCategoryFilter,
    setProductLimit,
    setProductPage, setProductSearchFilter
} from "../../store/products/actions";
import ProductsRow from "../../components/partials/products-collection/product-row";
import cookie from "react-cookies";
import {updateSearchParams} from "../../utils";
import useEffectOnce from "../../utils/hooks/useEffectOnce";
import useOnUnmount from "../../utils/hooks/useOnUnmount";
import useTranslation from "../../utils/hooks/useTranslation";
import {Helmet} from "react-helmet";

function Shop ({products, decrementProductPage, incrementProductPage, setProductPage, getProductList, setProductLimit, setCategoryFilter, setProductSearchFilter}) {
    const { t } = useTranslation();
    let pageViewCookie = getPageViewCookie();
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageView, setPageView] = useState(pageViewCookie ? pageViewCookie : "grid");
    const {list, loading, error, page, limit, selected_category, search, total_pages} = products;
    const [ sortBy, setSortBy ] = useState( searchParams.get("sortBy") ? searchParams.get("sortBy") : 'default' );

    useEffectOnce(() => {
        const page = searchParams.get("page");
        const category = searchParams.get("category");

        if(page) {
            setProductPage(Number(page));
        }

        if(category) {
            setCategoryFilter(Number(category))
        }
    })

    useOnUnmount(() => {
        setProductPage(1);
        setCategoryFilter(null);
    }, []);

    useEffect( () => {
        if(loading) return;

        if(search !== '' && search.length < 2) return;

        let offset = document.querySelector( '.main-content' ).getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout( () => {
            window.scrollTo( { top: offset, behavior: 'smooth' } );
        }, 200 );

        getProductList();
    }, [ page, limit, sortBy, selected_category, search ] )

    function onLimitChange ( e ) {
        setProductPage(1);
        setProductLimit(e.target.value);
    }

    function onSortByChange ( e ) {
        const {name, value} = e?.target;
        let params = {
            [name]: value,
            page: 1,
        };

        params = updateSearchParams(searchParams, params);
        setSearchParams(params);
        setProductPage(1);
        setSortBy( value );
    }

    function sidebarToggle ( e ) {
        let body = document.querySelector( 'body' );
        e.preventDefault();
        if ( body.classList.contains( 'sidebar-opened' ) ) {
            body.classList.remove( 'sidebar-opened' );
        } else {
            body.classList.add( 'sidebar-opened' );
        }
    }

    function getPageViewCookie() {
        return cookie.load("PAGE_VIEW");
    }

    function setPageViewCookie(view) {
        cookie.save("PAGE_VIEW", view, {
            "path": "/"
        });
    }

    if ( error ) {
        return <div>{ error.message }</div>
    }

    return (
        <>
            <Helmet>
                <title>SSD - {t("label.products")}</title>
            </Helmet>

            <main className="main">
                <ShopBanner />

                <div className="container">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><ALink href="/"><i className="icon-home"></i></ALink></li>
                            <li className="breadcrumb-item active"><ALink href={"/shop"}>Shop</ALink></li>
                        </ol>
                    </nav>

                    <div className="row">
                        <div className="col-lg-9 main-content">
                            <nav className="toolbox sticky-header mobile-sticky">
                                <div className="toolbox-left">
                                    {/*eslint-disable-next-line*/}
                                    <a href="#" className="sidebar-toggle" onClick={ e => sidebarToggle( e ) }>
                                        <svg data-name="Layer 3" id="Layer_3" viewBox="0 0 32 32"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <line x1="15" x2="26" y1="9" y2="9" className="cls-1"></line>
                                            <line x1="6" x2="9" y1="9" y2="9" className="cls-1"></line>
                                            <line x1="23" x2="26" y1="16" y2="16" className="cls-1"></line>
                                            <line x1="6" x2="17" y1="16" y2="16" className="cls-1"></line>
                                            <line x1="17" x2="26" y1="23" y2="23" className="cls-1"></line>
                                            <line x1="6" x2="11" y1="23" y2="23" className="cls-1"></line>
                                            <path
                                                d="M14.5,8.92A2.6,2.6,0,0,1,12,11.5,2.6,2.6,0,0,1,9.5,8.92a2.5,2.5,0,0,1,5,0Z"
                                                className="cls-2"></path>
                                            <path d="M22.5,15.92a2.5,2.5,0,1,1-5,0,2.5,2.5,0,0,1,5,0Z" className="cls-2"></path>
                                            <path d="M21,16a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" className="cls-3"></path>
                                            <path
                                                d="M16.5,22.92A2.6,2.6,0,0,1,14,25.5a2.6,2.6,0,0,1-2.5-2.58,2.5,2.5,0,0,1,5,0Z"
                                                className="cls-2"></path>
                                        </svg>
                                        <span>Filter</span>
                                    </a>

                                    {/* CAN BE ENABLED IN THE FUTURE WHEN SORTING FILTERS ARE APPLIED FROM API */}
                                    {/*<div className="toolbox-item toolbox-sort">*/}
                                    {/*    <label>{t("message.sort_by")}:</label>*/}

                                    {/*    <div className="select-custom">*/}
                                    {/*        <select name="orderby" className="form-control" value={ sortBy } onChange={ e => onSortByChange( e ) }>*/}
                                    {/*            <option value="default">{t("message.default_sorting")}</option>*/}
                                    {/*            <option value="popularity">{t("message.sort_by_popularity")}</option>*/}
                                    {/*            <option value="rating">{t("message.sort_by_average_rating")}</option>*/}
                                    {/*            <option value="date">{t("message.sort_by_newness")}</option>*/}
                                    {/*            <option value="price">{t("message.sort_by_price_low_to_high")}</option>*/}
                                    {/*            <option value="price-desc">{t("message.sort_by_price_high_to_low")}</option>*/}
                                    {/*        </select>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className={"toolbox-item"}>
                                        <input type="search" className="form-control search" name="q" id="q" placeholder={t("label.search") + "..."} value={ search }
                                               required onChange={ ( e ) => setProductSearchFilter( e.target.value ) } />
                                    </div>
                                </div>

                                <div className="toolbox-right">
                                    <div className="toolbox-item toolbox-show">
                                        <label>{t("label.show")}:</label>

                                        <div className="select-custom">
                                            <select name="count" className="form-control" value={ limit } onChange={ ( e ) => onLimitChange( e ) }>
                                                <option value="12">12</option>
                                                <option value="24">24</option>
                                                <option value="36">36</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="toolbox-item layout-modes">
                                        <div onClick={() => {
                                            setPageView("grid"); setPageViewCookie("grid");
                                        }} className={`layout-btn btn-grid ${pageView === 'grid' ? 'active' : ''}`} title="Grid">
                                            <i className="icon-mode-grid"></i>
                                        </div>
                                        <div onClick={() => {
                                            setPageView("list"); setPageViewCookie("list")
                                        }} className={`layout-btn btn-list ${pageView === 'list' ? 'active' : ''}`} title="List">
                                            <i className="icon-mode-list"></i>
                                        </div>
                                    </div>
                                </div>
                            </nav>

                            {pageView === 'grid' && <ProductsGrid products={list} loading={loading} perPage={limit}/>}
                            {pageView === 'list' && <ProductsRow products={list} loading={loading} perPage={limit}/>}
                            <nav className="toolbox toolbox-pagination">
                                <div className="toolbox-item toolbox-show">
                                    <label>{t("label.show")}:</label>

                                    <div className="select-custom">
                                        <select name="count" className="form-control" value={ limit } onChange={ e => onLimitChange( e ) }>
                                            <option value="12">12</option>
                                            <option value="24">24</option>
                                            <option value="36">36</option>
                                        </select>
                                    </div>
                                </div>
                                <Pagination page={page}
                                            totalPage={ total_pages }
                                            incrementPage={incrementProductPage}
                                            decrementPage={decrementProductPage}
                                            setProductPage={(page) => setProductPage(page)} />
                            </nav>
                        </div>

                        <ShopSidebarOne />
                    </div>

                    <div className="mb-4"></div>
                </div>
            </main>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        products: state.products,
    }
}

const mapDispatchToProps = {
    setProductPage,
    incrementProductPage,
    decrementProductPage,
    getProductList,
    setProductLimit,
    setCategoryFilter,
    setProductSearchFilter
}

export default connect( mapStateToProps, mapDispatchToProps )( Shop );