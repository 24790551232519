import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from "../../common/ALink";
import useTranslation from "../../../utils/hooks/useTranslation";
import {removeXSSAttacks} from "../../../utils";

function BlogSingle ( props ) {
    const { t } = useTranslation();
    const { blog, loading } = props;

    let date = new Date( blog && blog.created_at );
    let monthArray = [ t("label.jan"), t("label.feb"), t("label.mar"),
        t("label.apr"), t("label.may"), t("label.jun"),
        t("label.jul"), t("label.aug"), t("label.sep"),
        t("label.oct"), t("label.nov"), t("label.dec") ];

    return (
        loading ?
            <div className="skel-post"></div>
            :
            <>
                <article className="single">
                    <figure className="post-media">
                        <LazyLoadImage
                            alt="post_image"
                            src={ process.env.REACT_APP_PUBLIC_ASSET_URI + blog?.image?.url }
                            threshold={ 500 }
                            width="100%"
                            height="auto"
                            effect="blur"
                        />
                    </figure>
                    <div className="post-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-1">
                                    <div className="post-date">
                                        <span className="day">{ `${ date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate() }` }</span>
                                        <span className="month">{ monthArray[ date.getUTCMonth() ] }</span>
                                    </div>
                                </div>
                                <div className="col-lg-11">
                                    <h2 className="post-title">
                                        <ALink href="#">{ blog.title }</ALink>
                                    </h2>
                                    <div className={"mt-1"}>
                                        <div dangerouslySetInnerHTML={removeXSSAttacks(blog.description)}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </>
    )
}

export default BlogSingle;