import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import SlideToggle from 'react-slide-toggle';

// Import Custom Component
import ALink from "../ALink";
import { mainMenu } from "../../../utils/data/menu";
import {connect} from "react-redux";
import {setCategoryFilter, setProductPage} from "../../../store/products/actions";
import useTranslation from "../../../utils/hooks/useTranslation";
import SearchInputForm from "../../../forms/searchInputForm";
import {partners} from "../../../utils/data/partners";
import {doLogout} from "../../../store/auth/actions";

function MobileMenu ({categories, setCategoryFilter, user, setProductPage, doLogout}) {
    const { t } = useTranslation();
    const location = useLocation();
    const pathname = location.pathname;


    useEffect( () => {
        closeMobileMenu();
    }, [location] );

    function closeMobileMenu () {
        document.querySelector( "body" ).classList.remove( "mmenu-active" );

        if ( document.querySelector( ".menu-toggler" ) ) {
            document.querySelector( ".menu-toggler" ).classList.remove( "active" );
        }
    }

    function handleCategoryChange(categoryId) {
        setCategoryFilter(categoryId);
        setProductPage(1);
    }

    return (
        <>
            <div className="mobile-menu-overlay" onClick={ closeMobileMenu }></div>
            <div className="mobile-menu-container">
                <div className="mobile-menu-wrapper">
                    <span className="mobile-menu-close" onClick={ closeMobileMenu }><i className="fa fa-times"></i></span>
                    <nav className="mobile-nav">
                        <ul className="mobile-menu">
                            <li className={ pathname === '/' ? 'active' : '' }>
                                <ALink href="/">{t("label.home")}</ALink>
                            </li>
                            <li><ALink href="/about-us">{t("message.about_us")}</ALink></li>
                            <li className={ pathname.startsWith( '/shop' ) ? 'active' : '' }>
                                {
                                    <SlideToggle collapsed={ true }>
                                        { ( { onToggle, setCollapsibleElement, toggleState } ) => (
                                            <>
                                                <ALink href="/shop" className={ `${ toggleState.toLowerCase() }` }>{t("label.categories")}<span className="mmenu-btn" onClick={ e => { e.preventDefault(); onToggle(); } }></span></ALink>

                                                <ul ref={ setCollapsibleElement }>
                                                    {
                                                        categories?.map( ( category, index ) => (
                                                            <li key={ "menu-item" + index }>
                                                                <ALink href={ `/shop?category=${ category.id }` } onClick={() => handleCategoryChange(category.id)}>{ category.name }</ALink>
                                                            </li>
                                                        ) )
                                                    }
                                                </ul>
                                            </>
                                        ) }
                                    </SlideToggle>
                                }
                            </li>

                            <li className={ pathname.startsWith( '/solutions' ) ? 'active' : '' }>
                                {
                                    <SlideToggle collapsed={ true }>
                                        { ( { onToggle, setCollapsibleElement, toggleState } ) => (
                                            <>
                                                <ALink href="/solutions" className={ `${ toggleState.toLowerCase() }` }>{t("label.solutions")}<span className="mmenu-btn" onClick={ e => { e.preventDefault(); onToggle(); } }></span></ALink>

                                                <ul ref={ setCollapsibleElement }>
                                                    {
                                                        mainMenu.solutions?.map( ( solution, index ) => (
                                                            <li key={ "menu-item" + index }>
                                                                <ALink href={ `/solutions${ solution.url }` }>{ t(solution.title) }</ALink>
                                                            </li>
                                                        ) )
                                                    }
                                                </ul>
                                            </>
                                        ) }
                                    </SlideToggle>
                                }
                            </li>

                            <li>
                                {
                                    <SlideToggle collapsed={ true }>
                                        { ( { onToggle, setCollapsibleElement, toggleState } ) => (
                                            <>
                                                <ALink href="#" className={ `${ toggleState.toLowerCase() }` }>{t("label.partners")}<span className="mmenu-btn" onClick={ e => { e.preventDefault(); onToggle(); } }></span></ALink>

                                                <ul ref={ setCollapsibleElement }>
                                                    {
                                                        partners.list?.map( ( partner, index ) => (
                                                            <li key={ "partner-menu-item" + index }>
                                                                <a target={"_blank"} rel={"noopener noreferrer"} href={ partner.url}>{ partner.name }</a>
                                                            </li>
                                                        ) )
                                                    }
                                                </ul>
                                            </>
                                        ) }
                                    </SlideToggle>
                                }
                            </li>

                            <li><ALink href="/blog">{t("label.blog")}</ALink></li>

                        </ul>

                        <ul className="mobile-menu">
                            <li><ALink href="/contact-us">{t("message.contact_us")}</ALink></li>
                            {user && <li onClick={() => {doLogout()}}><ALink href="#">{t("label.logout")}</ALink></li>}
                            {!user && <li><ALink href="/login">{t("label.login")}</ALink></li>}
                            {user && <li><ALink href="/admin">{t("label.my_account")}</ALink></li>}
                            {!user && <li><ALink href="/forgot-password">{t("message.forgot_password")}</ALink></li>}
                        </ul>
                    </nav>

                    <SearchInputForm isMobile={true} />

                    <div className="social-icons">
                        <a href="https://www.facebook.com/dahua.al" target={"_blank"} rel={"noopener noreferrer"}
                           className="social-icon social-facebook icon-facebook"
                           title="Facebook"></a>
                        <a href="https://www.instagram.com/ssd_albania/" target={"_blank"} rel={"noopener noreferrer"}
                           className="social-icon social-instagram icon-instagram"
                           title="Instagram"></a>
                        <a href="https://www.youtube.com/channel/UC2bsk0iXdhTuiPcneqzYdYw" target={"_blank"} rel={"noopener noreferrer"}
                           className="social-icon social-youtube fab fa-youtube"
                           title="Youtube"></a>
                        <a href="https://www.linkedin.com/company/ssd-albania/" target={"_blank"} rel={"noopener noreferrer"}
                           className="social-icon social-linkedin fab fa-linkedin"
                           title="Linkedin"></a>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        categories: state.categories.list
    }
}

const mapDispatchToProps = {setCategoryFilter, setProductPage, doLogout}

export default connect( mapStateToProps, mapDispatchToProps )( MobileMenu );