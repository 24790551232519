import SlideToggle from 'react-slide-toggle';
import { useMemo } from 'react';
import StickyBox from 'react-sticky-box';
import Tree from 'rc-tree';
import {solutions} from "../../../utils/data/solutions";
import useTranslation from "../../../utils/hooks/useTranslation";
import useOnUnmount from "../../../utils/hooks/useOnUnmount";
import {useNavigate} from "react-router-dom";

const TreeNode = ( props ) => {
    return (
        <>
            { props.name }
            { props.count && <span className="products-count">({props.count})</span> }
        </>
    )
}

function SolutionSidebarOne ({display, right, selectedSolution} ) {
    const { t } = useTranslation();
    const navigte = useNavigate();

    const solutionTree = useMemo( () => {
        let sols = solutions ? solutions : [];
        let result = [];
        result = sols.reduce( ( acc, cur ) => {
            let newNode = {
                key: cur.id,
                title: <TreeNode name={ t(cur.title) } />,
                children: fixChildrenFormat(cur.list)
            };

            acc.push( newNode );

            return acc;
        }, [] );

        return result;
    }, [ solutions ] );

    useOnUnmount( () => {
        closeSidebar();
    })

    function fixChildrenFormat(children) {
        let childNodes = [];
        children?.forEach((child) => {
            childNodes.push({
                key: child.slug,
                title: <TreeNode name={ t(child.title) } />,
                children: []
            });
        })

        return childNodes;
    }

    function goToSolutionDetails ( selected ) {
        if(isNaN(selected[0])) {
            navigte(`/solutions/${selected[0]}`);
        }
    }

    function closeSidebar () {
        document.querySelector( 'body' ).classList.contains( 'sidebar-opened' ) && document.querySelector( 'body' ).classList.remove( 'sidebar-opened' );
    }

    return (
        <>
            <div className="sidebar-overlay" onClick={ closeSidebar }></div>
            <aside className={ `sidebar-shop col-lg-3 mb-3 mobile-sidebar skeleton-body skel-shop-products loaded ${ display === 'none' ? 'd-lg-none' : '' } ${ right ? '' : 'order-lg-first' }` }>
                <StickyBox className="sidebar-wrapper" offsetTop={ 70 }>
                    <div className="widget">
                        <SlideToggle>
                            { ( { onToggle, setCollapsibleElement, toggleState } ) => (
                                <>
                                    <h3 className="widget-title">
                                        <a href="#" onClick={ ( e ) => { e.preventDefault(); onToggle() } } className={ toggleState === 'COLLAPSED' ? 'collapsed' : '' }>{t("label.solutions")}</a>
                                    </h3>
                                    <div className="overflow-hidden" ref={ setCollapsibleElement }>
                                        <div className="widget-body">
                                            <Tree
                                                className="no-icon cat-list border-0"
                                                selectable={ true }
                                                showIcon={ false }
                                                defaultExpandedKeys={ [ selectedSolution ] }
                                                switcherIcon={ ( props ) => {
                                                    return ( !props.isLeaf ?
                                                            <span className="toggle"></span>
                                                            : ''
                                                    )
                                                } }
                                                selectedKeys={ [ selectedSolution ] }
                                                treeData={ solutionTree }
                                                onSelect={ goToSolutionDetails }
                                            />
                                        </div>
                                    </div>
                                </>
                            ) }
                        </SlideToggle>
                    </div>
                </StickyBox>
            </aside>
        </>
    )
}

export default SolutionSidebarOne;