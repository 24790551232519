import React from 'react';
import Reveal from 'react-awesome-reveal';

// Import Custom Component
import OwlCarousel from '../../features/owl-carousel';

// Import Utils
import { fadeIn } from '../../../utils/data/keyframes'
import {solutionsSlider} from '../../../utils/data/slider';
import useTranslation from "../../../utils/hooks/useTranslation";
import SolutionTypeTwo from "../../features/solutions/solution-type-two";

export default function SolutionsSection ( props ) {
    const { t } = useTranslation();
    const { loading, solution } = props;

    return (
        <section className="blog-section pb-0">
            <div className="container">
                <h2 className="section-title heading-border border-0 ls-0 mb-4">{t("label.solutions")}</h2>

                <OwlCarousel adClass="owl-theme nav-outer nav-image-center show-nav-hover" options={ solutionsSlider }>

                    {
                        loading ?
                            [ 0, 1, 2, 3, 4 ].map( ( item, index ) =>
                                <div className="skel-pro skel-pro-grid" key={ "product-one" + index }></div>
                            )
                            :
                            solution?.map( ( item, index ) => (
                                <Reveal keyframes={ fadeIn } delay={ 100 } duration={ 1000 } triggerOnce
                                        key={ "product-one" + index }>
                                    <SolutionTypeTwo solution={ item } />
                                </Reveal>
                            ) )
                    }

                </OwlCarousel>
            </div>
        </section>
    );
}