import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from "../../common/ALink";
import OwlCarousel from '../owl-carousel';

// Import Utils
import { postSlider } from '../../../utils/data/slider';
import useTranslation from "../../../utils/hooks/useTranslation";

function SolutionTypeOne ( props ) {
    const {t} = useTranslation();
    const { adClass = '', solution } = props;

    return (
        <article className={ `post media-with-lazy ${adClass}` }>
            {
                solution.images &&
                ( solution.images.length > 1 ?
                        <OwlCarousel adClass="owl-theme post-slider mb-0 show-nav-hover" options={ postSlider }>
                            {
                                solution.images.map( ( image, index ) => (
                                    <figure className="post-media zoom-effect" key={ "Solution:" + index }>
                                        <div className="lazy-overlay"></div>

                                        <LazyLoadImage
                                            alt="post_image"
                                            src={ process.env.REACT_APP_PUBLIC_ASSET_URI + image.url }
                                            threshold={ 500 }
                                            width="100%"
                                            height="auto"
                                            effect="blur"
                                        />

                                    </figure>
                                ) )
                            }
                        </OwlCarousel>
                        :
                        <figure className="post-media zoom-effect">
                            <div className="lazy-overlay"></div>

                            <LazyLoadImage
                                alt="post_image"
                                src={ process.env.REACT_APP_PUBLIC_ASSET_URI + solution.images[ 0 ].url }
                                threshold={ 500 }
                                width="100%"
                                height="auto"
                                effect="blur"
                            />

                        </figure>
                )
            }

            <div className="post-body">
                <h2 className="post-title">
                    { t(solution.title) }
                </h2>

                <div className="post-content">
                    <ul>
                        {solution.list.map((sol) => (
                            <li key={"solution-"+sol.slug}><ALink href={`/solutions/${sol.slug}`}>{t(sol.title)}</ALink></li>
                        ))}
                    </ul>
                </div>
            </div>
        </article >
    )
}

export default SolutionTypeOne;