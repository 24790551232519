import useTranslation from "../../../utils/hooks/useTranslation";
import {connect} from "react-redux";

function ShopBanner ({products, categories}) {
    const {t} = useTranslation();

    const defaultCover = process.env.REACT_APP_PUBLIC_ASSET_URI +"bundles/app/frontend/images/breadcumb-bg.jpg";

    const findCategoryCover = function (){
        const selectedCategoryId = products.selected_category;
        const categoriesList = categories.list;

        if(selectedCategoryId){
            const selectedMainCategory = findSelectedCategoryById(categoriesList, selectedCategoryId);

            if(selectedMainCategory && selectedMainCategory.cover){
                return process.env.REACT_APP_PUBLIC_ASSET_URI+selectedMainCategory.cover.url;
            }
        }

        return defaultCover;
    }

    function findSelectedCategoryById(categories, id) {
        return categories.find((category) => {
            if(category.id == id){
                return true;
            } else {
                return findSelectedCategoryById(category.children, id);
            }
        })
    }

    findCategoryCover();

    return (
        <div className="category-banner-container bg-gray">
            <div className="category-banner banner text-uppercase"
                style={ {backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: "url(" + findCategoryCover() + ")"} }>
            </div >
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        products: state.products,
        categories: state.categories
    }
}

export default connect( mapStateToProps, null )( ShopBanner );