import LOGO from "../assets/images/logo.jpg"

const BrowserNotifications = () => {
    const sendNotification = (title, body, onClick) => {
        const notification = new Notification(title, {
            icon: LOGO,
            body: body
        })

        notification.onclick = onClick ?? null;
    }

    const showNotification = (title, body, onClick) => {
        if(!("Notification" in window)) {
            alert("This browser does not support system notifications!");
        } else if(Notification.permission === "granted") {
            sendNotification(title, body, onClick);
        } else if(Notification.permission !== "denied") {
            Notification.requestPermission((permission)=> {
                if (permission === "granted") {
                    sendNotification(title, body, onClick);
                }
            });
        }
    }

    return {
       showNotification
    }
}

export default BrowserNotifications;