import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Outlet, useParams} from 'react-router-dom';

// Import Custom Component
import useTranslation from "../../utils/hooks/useTranslation";
import SolutionTypeOne from "../../components/features/solutions/solution-type-one";
import BlogSidebar from "../../components/partials/blog/blog-sidebar";
import {solutions} from "../../utils/data/solutions";

function Solutions() {
    const {t} = useTranslation();
    const params = useParams();

    useEffect(() => {
        let offset = document.querySelector('.main').getBoundingClientRect().top + window.pageYOffset - 68;
        setTimeout(() => {
            window.scrollTo({top: offset, behavior: 'smooth'});
        }, 200);

    })

    const bannerImageUrl = process.env.REACT_APP_PUBLIC_ASSET_URI + "/bundles/app/frontend/images/breadcumb-bg-solutions.jpg";

    return (
        <>
            <Helmet>
                <title>{t("message.ssd_solutions")}</title>
            </Helmet>

            <main className="main">
                <div className="category-banner-container bg-gray">
                    <div className="category-banner banner text-uppercase"
                         style={{background: `no-repeat 60%/cover url(${bannerImageUrl})`}}>
                        <div className="container position-relative">
                            <div className={"text-center"}>
                                <h2 className="m-b-1 banner-white-text text-transform-none">{t("label.solutions")}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home"><i className="icon-home"></i></a></li>
                            <li className="breadcrumb-item active" aria-current="page">{t("label.solutions")}</li>
                        </ol>
                    </div>
                </nav>


                <div className="container">
                    <div className="row">
                        {params.slug
                            ? <Outlet/>
                            : (
                                <>
                                    <div className={"col-12"}>
                                        <div
                                            className={`blog-section row pt-0 pb-3 skeleton-body skel-shop-products loaded`}>
                                            {
                                                solutions.map((solution, index) => (
                                                    <div className="col-md-6 col-lg-4" key={"SolutionTypeOne" + index}>
                                                        <SolutionTypeOne solution={solution}/>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </main>
        </>
    );
}

export default Solutions;