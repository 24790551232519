import React from 'react';

// Import Custom Component
import ALink from "../ALink";
import {connect} from "react-redux";
import useTranslation from "../../../utils/hooks/useTranslation";

function StickyNavbar ( { user } ) {
    const { t } = useTranslation();

    function toggleCart ( e ) {
        e.preventDefault();
        document.querySelector( 'body' ).classList.toggle( 'cart-opened' );
    }

    return (
        <div className="sticky-navbar">
            <div className="sticky-info">
                <ALink href="/"><i className="icon-home"></i>{t("label.home")}</ALink>
            </div>
            <div className="sticky-info">
                <ALink href="/shop" className=""><i className="icon-bars"></i>{t("label.products")}</ALink>
            </div>
            {!user && <div className="sticky-info">
                <ALink href={`/login`} className=""><i className="icon-user-2"></i>{t("label.my_account")}</ALink>
            </div>}
            <div className="sticky-info">
                <a href="#" onClick={toggleCart}><i className="icon-shopping-cart"></i>{t("label.cart")}</a>
            </div>
        </div>
    )
}

function mapStateToProps ( state ) {
    return {
        user: state.auth.user,
    }
}

export default connect( mapStateToProps, null )( StickyNavbar );