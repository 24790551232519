import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation, Outlet, useParams} from 'react-router-dom';

// Import Custom Component
import BlogTypeOne from '../../components/features/blogs/blog-type-one';
import BlogSidebar from '../../components/partials/blog/blog-sidebar';
// import Pagination from '../../components/features/pagination';
import useTranslation from "../../utils/hooks/useTranslation";
import {connect} from "react-redux";

function Blog({ blog }) {
    const {t} = useTranslation();
    const location = useLocation();
    const params = useParams();
    const query = location.search;
    const loading = false;
    const {list} = blog;
    const blogs = list;

    useEffect(() => {
        let offset = document.querySelector('.main').getBoundingClientRect().top + window.pageYOffset - 68;
        setTimeout(() => {
            window.scrollTo({top: offset, behavior: 'smooth'});
        }, 200);

    }, [query])

    return (
        <>
            <Helmet>
                <title>{t("message.ssd_blog")}</title>
            </Helmet>

            <main className="main">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home"><i className="icon-home"></i></a></li>
                            <li className="breadcrumb-item active" aria-current="page">{t("label.blog")}</li>
                        </ol>
                    </div>
                </nav>

                <div className="container">
                    <div className="row">
                        { params.id
                            ? (<Outlet />)
                            : (
                                <div className="col-lg-9">
                                    <div
                                        className={`blog-section row pt-0 pb-3 skeleton-body skel-shop-products ${loading ? '' : 'loaded'}`}>
                                        {
                                            blogs?.slice(0, 6).map((blog, index) => (
                                                <div className="col-md-6 col-lg-4" key={"BlogTypeOne" + index}>
                                                    <BlogTypeOne blog={blog}/>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    {/*<div className="d-flex justify-content-end mb-5">*/}
                                    {/*    <Pagination totalPage={ totalPage } />*/}
                                    {/*</div>*/}
                                </div>
                            )}

                        <BlogSidebar/>
                    </div>
                </div>
            </main>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        blog: state.blogs,
    }
}

export default connect( mapStateToProps, null )( Blog );