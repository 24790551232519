import { connect } from 'react-redux';

// Import Custom Component
import ALink from "./ALink";
import CartMenu from "./partials/cart-menu";
import MainMenu from "./partials/main-menu";
import SearchForm from "./partials/search-form";
import LoginModal from "../features/modals/login-modal";
import useTranslation from "../../utils/hooks/useTranslation";
import usePrice from "../../utils/hooks/usePrice";
import {utilityData} from "../../utils/data/utilityData";
import Logo from "../../assets/images/logo.jpg";
import PhoneIcon from "../../assets/images/phone.png";
import {doLogout} from "../../store/auth/actions";
import React from "react";
import MyAccountLink from "../features/my-account-link";

function Header ( { adClass = '', user, doLogout } ) {
    const { language, setLanguage, t } = useTranslation();
    const { currency, setCurrency } = usePrice();

    function handleLanguageChange(language) {
        setLanguage(language);
        window.location.reload();
    }

    function handleCurrencyChange(currency) {
        setCurrency(currency);
        window.location.reload();
    }

    function getFormattedLanguage(language) {
        switch (language) {
            case "en":
                return "us";
            case "sq":
                return "al";
        }
    }

    function openMobileMenu ( e ) {
        e.preventDefault();
        document.querySelector( "body" ).classList.toggle( "mmenu-active" );
        e.currentTarget.classList.toggle( "active" );
    }

    return (
        <header className={ `header ${ adClass }` }>
            <div className="header-top">
                <div className="container">
                    <div className="header-right header-dropdowns ml-0 ml-sm-auto w-sm-100">
                        <div className="header-dropdown dropdown-expanded d-none d-lg-block">
                            <ALink href="#">Links</ALink>
                            <div className="header-menu">
                                <ul>
                                    <li><ALink href="/contact-us">{t("message.contact_us")}</ALink></li>
                                    <li><ALink href="/blog">{t("label.blog")}</ALink></li>
                                    {user && <li><MyAccountLink /></li>}
                                    {!user && <LoginModal/>}
                                    {user && <li onClick={() => {doLogout()}}><ALink href="#">{t("label.logout")}</ALink></li>}
                                </ul>
                            </div>
                        </div>

                        <span className="separator"></span>

                        <div className="header-dropdown">
                            <ALink href="#"><i className={`flag-${getFormattedLanguage(language)} flag`}></i>{language.toUpperCase()}</ALink>
                            <div className="header-menu">
                                <ul>
                                    {utilityData.languages?.map((language, index) => (
                                        <li key={"language-"+index} onClick={() => handleLanguageChange(language.symbol)} className={"cursor-pointer p-2 d-flex align-items-center"}>
                                            <i className={`flag-${getFormattedLanguage(language.symbol)} flag mr-2`}></i>{t(language.label)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="header-dropdown mr-auto mr-sm-3 mr-md-0">
                            <ALink href="#">{currency}</ALink>
                            <div className="header-menu">
                                <ul>
                                    {utilityData.currencies?.map((currency, index) => (
                                        <li key={"currency-"+index} onClick={() => handleCurrencyChange(currency.name)} className={"cursor-pointer p-2 d-flex align-items-center"}>
                                            <span>{currency.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <span className="separator"></span>

                        <div className="social-icons">
                            <a href="https://www.facebook.com/dahua.al" target={"_blank"} rel={"noopener noreferrer"}
                               className="social-icon social-facebook icon-facebook"
                               title="Facebook"></a>
                            <a href="https://www.instagram.com/ssd_albania/" target={"_blank"} rel={"noopener noreferrer"}
                               className="social-icon social-instagram icon-instagram"
                               title="Instagram"></a>
                            <a href="https://www.youtube.com/channel/UC2bsk0iXdhTuiPcneqzYdYw" target={"_blank"} rel={"noopener noreferrer"}
                               className="social-icon social-youtube fab fa-youtube"
                               title="Youtube"></a>
                            <a href="https://www.linkedin.com/company/ssd-albania/" target={"_blank"} rel={"noopener noreferrer"}
                               className="social-icon social-linkedin fab fa-linkedin"
                               title="Linkedin"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-middle sticky-header mobile-sticky">
                <div className="container">
                    <div className="header-left col-lg-2 w-auto pl-0">
                        <button className="mobile-menu-toggler text-primary mr-2" type="button" onClick={ openMobileMenu }>
                            <i className="fa fa-bars"></i>
                        </button>

                        <ALink href="/" className="logo">
                            <img src={Logo} alt="Logo" />
                        </ALink>
                    </div>

                    <div className="header-right w-lg-max">
                        <SearchForm />

                        <div className="header-contact d-none d-lg-flex pl-4 pr-4">
                            <img alt="phone" src={PhoneIcon} width="30" height="30" className="pb-1" />
                            <h6><span>{t("message.call_us_now")}</span><a href="tel:+355696022333" className="text-dark font1">+355 69 60 22 333</a></h6>
                        </div>

                        {!user && <ALink href={"/login"} className="header-icon" title="login"><i
                            className="icon-user-2"></i></ALink>}

                        <CartMenu />
                    </div>
                </div>
            </div>

            <div className="header-bottom sticky-header desktop-sticky d-none d-lg-block">
                <div className="container">
                    <MainMenu />
                </div>
            </div>
        </header >
    )
}

const mapStateToProps = ( state ) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = {doLogout}

export default connect( mapStateToProps, mapDispatchToProps )( Header );