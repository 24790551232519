import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { countTo } from '../utils';
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import useTranslation from "../utils/hooks/useTranslation";

export default function AboutUs () {
    const { t } = useTranslation();
    const location = useLocation();
    useEffect( () => {
        countTo();
        let offset = document.querySelector( location.hash ? location.hash : 'body' ).getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout( () => {
            window.scrollTo( { top: offset, behavior: 'smooth' } );
        }, 50 );
    }, [location] )

    const renderHeaderMeta = () => {
        return (
            <Helmet>
                <title>SSD - {t("message.about_us")}</title>
            </Helmet>
        )
    }

    return (
        <main className="main about">
            {renderHeaderMeta()}
            <div className="page-header page-header-bg text-left"
                 style={ { background: "50%/cover #D4E1EA url("+process.env.REACT_APP_PUBLIC_ASSET_URI+"/bundles/app/frontend/images/about/missionandvission.jpg)" } }>
                <div className="container">
                    <h1><span>{t("message.about_us")}</span>
                        {t("message.our_company")}</h1>
                    <Link to="/contact-us" className="btn btn-dark">{t("label.contact")}</Link>
                </div>
            </div>

            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i></Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{t("message.about_us")}</li>
                    </ol>
                </div>
            </nav>

            <div className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div id="summary" className="container">
                                <h2 className="subtitle">{t("label.summary")}</h2>
                                <p>{t("message.about_us_summary")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div id="our-mission" className="container">
                                <h2 className="subtitle">{t("message.our_mission")}</h2>
                                <p>{t("message.about_us_our_mission")}</p>
                            </div>
                            <div className="container mt-lg-5">
                                <h2 className="subtitle">{t("label.objectives")}</h2>
                                <p>{t("message.about_us_our_objectives")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="features-section bg-gray">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <figure>
                                <div className="lazy-overlay"></div>
                                <LazyLoadImage
                                    alt="product"
                                    src={process.env.REACT_APP_PUBLIC_ASSET_URI + "/bundles/app/frontend/images/about/1.jpg"}
                                    threshold={500}
                                    effect="black and white"
                                    width="100%"
                                    height="100%"
                                />
                            </figure>
                        </div>

                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-6">
                                    <figure>
                                        <div className="lazy-overlay"></div>
                                        <LazyLoadImage
                                            alt="product"
                                            src={process.env.REACT_APP_PUBLIC_ASSET_URI + "/bundles/app/frontend/images/about/3.jpg"}
                                            threshold={500}
                                            effect="black and white"
                                            width="100%"
                                            height="auto"
                                        />
                                    </figure>
                                </div>
                                <div className="col-lg-6">
                                    <figure>
                                        <div className="lazy-overlay"></div>
                                        <LazyLoadImage
                                            alt="product"
                                            src={process.env.REACT_APP_PUBLIC_ASSET_URI + "/bundles/app/frontend/images/about/4.jpg"}
                                            threshold={500}
                                            effect="black and white"
                                            width="100%"
                                            height="auto"
                                        />
                                    </figure>
                                </div>
                                <div className="col-lg-6">
                                    <figure>
                                        <div className="lazy-overlay"></div>
                                        <LazyLoadImage
                                            alt="product"
                                            src={process.env.REACT_APP_PUBLIC_ASSET_URI + "/bundles/app/frontend/images/about/5.jpg"}
                                            threshold={500}
                                            effect="black and white"
                                            width="100%"
                                            height="auto"
                                        />
                                    </figure>
                                </div>
                                <div className="col-lg-6">
                                    <figure>
                                        <div className="lazy-overlay"></div>
                                        <LazyLoadImage
                                            alt="product"
                                            src={process.env.REACT_APP_PUBLIC_ASSET_URI + "/bundles/app/frontend/images/about/6.jpg"}
                                            threshold={500}
                                            effect="black and white"
                                            width="100%"
                                            height="auto"
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div id="vision" className="container">
                                <h2 className="subtitle">{t("label.vision")}</h2>
                                <p className={"long-description-format"}>{t("message.about_us_our_vision")}</p>
                            </div>
                            <div className="container">
                                <h2 className="subtitle">{t("label.values")}</h2>
                                <p className={"long-description-format"}>{t("message.about_us_our_values")}</p>
                            </div>
                            <div className="container">
                                <h2 className="subtitle">{t("label.philosophy")}</h2>
                                <p className={"long-description-format"}>{t("message.about_us_our_philosophy")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="container">
                                <h2 className="subtitle">{t("label.strategy")}</h2>
                                <p className={"long-description-format"}>{t("message.about_us_our_strategy")}</p>
                            </div>
                            <div className="container">
                                <h2 className="subtitle">{t("label.development")}</h2>
                                <p className={"long-description-format"}>{t("message.about_us_our_development")}</p>
                            </div>
                            <div id="long-term-goals" className="container">
                                <h2 className="subtitle">{t("message.long_term_goals")}</h2>
                                <p className={"long-description-format"}>{t("message.about_us_our_long_term_goals")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="features-section bg-gray">
                <div id="rules" className="container">
                    <h2 className="subtitle">{t("message.ssd_rules")}</h2>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_quality_comes_first")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_care_takes_to_grows")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_client_is_always_right")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_being_correct")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_technology_is_foundation")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_pure_competition")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_work_with_passion")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_efficient_work")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_climb_the_stairs")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-box bg-white">
                                <div className="feature-box-content p-0">
                                    <p>{t("message.ssd_rules_room_for_improvement")}.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}