export const solutions = [
    {
        id: 1,
        title: "message.bank_and_finance",
        list: [
            {
                id: 11,
                title: "message.bank_and_finance",
                slug: "bank-finance"
            }
        ],
        images: [
            {
                id: 1,
                url: "images/solutions/sol1.jpg"
            }
        ]
    },
    {
        id: 2,
        title: "label.residential",
        list: [
            {
                id: 21,
                title: "label.residential",
                slug: "residential"
            }
        ],
        images: [
            {
                id: 2,
                url: "images/solutions/sol2.jpg"
            }
        ]
    },
    {
        id: 3,
        title: "label.retail",
        list: [
            {
                id: 31,
                title: "label.retail",
                slug: "retail"
            }
        ],
        images: [
            {
                id: 3,
                url: "images/solutions/sol3.jpg"
            }
        ]
    },
    {
        id: 4,
        title: "message.sport_and_free_time",
        list: [
            {
                id: 41,
                title: "label.restaurant",
                slug: "restaurant"
            },
            {
                id: 42,
                title: "label.hotels",
                slug: "hotel"
            }
        ],
        images: [
            {
                id: 4,
                url: "images/solutions/sol4.jpg"
            }
        ]
    },
    {
        id: 5,
        title: "label.transport",
        list: [
            {
                id: 51,
                title: "label.traffic",
                slug: "traffic",
            },
            {
                id: 52,
                title: "label.seaport",
                slug: "seaport",
            },
            {
                id: 53,
                title: "label.bus",
                slug: "bus",
            },
            {
                id: 54,
                title: "message.entrance_and_exit",
                slug: "entrance-exit",
            },
            {
                id: 55,
                title: "label.parking",
                slug: "parking",
            },
            {
                id: 56,
                title: "label.airport",
                slug: "airport",
            },
        ],
        images: [
            {
                id: 5,
                url: "images/solutions/sol5.jpg"
            }
        ]
    },
    {
        id: 6,
        title: "label.energy",
        list: [
            {
                id: 61,
                title: "message.electric_station",
                slug: "electric",
            },
            {
                id: 62,
                title: "message.gas_station",
                slug: "gas-station",
            },
            {
                id: 63,
                title: "message.energy_and_oil",
                slug: "energy-oil",
            }
        ],
        images: [
            {
                id: 6,
                url: "images/solutions/sol6.jpg"
            }
        ]
    }
]