import React, {useEffect, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';

// Import Custom Component
import ProductNav from '../product-nav';
import ALink from '../../../common/ALink';
import SingleTabOne from "../tabs/single-tab-one";
import {removeXSSAttacks} from "../../../../utils";
import useTranslation from "../../../../utils/hooks/useTranslation";
import usePrice from "../../../../utils/hooks/usePrice";
import {connect} from "react-redux";
import {showLoginModal} from "../../../../store/modal/actions";
import {addProductToCart, saveCart} from "../../../../store/cart/actions";

function ProductDetailOne(props) {
    const {t} = useTranslation();
    const {c} = usePrice();
    const {
        product,
        adClass = "col-lg-7 col-md-6",
        prev,
        next,
        isNav = true,
        parent = ".product-single-default",
        isSticky = false,
        user,
        showLoginModal,
        addProductToCart,
        saveCart
    } = props;

    useEffect(() => {
        if (product) {
            document.querySelector(`${parent} .shopping-cart`) && document.querySelector(`${parent} .shopping-cart`).classList.remove('disabled');
            document.querySelector(`${parent} .sticky-cart .add-cart`) && document.querySelector(`${parent} .sticky-cart .add-cart`).classList.remove('disabled');
        }
    }, []);

    function parseQuantity(quantity) {
        if (quantity > 100) {
            return <span>100+</span>;
        } else if (quantity > 50) {
            return <span>50+</span>;
        }

        return <span>{quantity}</span>
    }

    function onAddCartClick(e) {
        e.preventDefault();

        if (!user) {
            showLoginModal();
            return;
        }

        const cartProduct = {
            id: product.id,
            quantity: 1,
            type: 'product',
            name: product.name,
            maxQuantity: product.quantity,
            price: product.price,
            images: product.images,
        }

        addProductToCart(cartProduct);
        saveCart();
    }

    return (
        <>
            <div className={`skel-pro skel-detail ${adClass}`}></div>
            {
                product &&
                <div className={`product-single-details ${adClass}`}>
                    <h1 className="product-title">{product.name}</h1>

                    {
                        isNav ?
                            <ProductNav prev={prev} next={next}/>
                            : ""
                    }

                    <hr className="short-divider"/>

                    {product.price > 0 && <div className="price-box">
                        <span className="product-price">{c(product.price)}</span>
                    </div>}

                    {user && product.quantity && <div>
                        <p>{t("label.quantity")}: {parseQuantity(product.quantity)}</p>
                    </div>}

                    <div className="product-desc">
                        <p>{product.short_description}</p>
                        <p dangerouslySetInnerHTML={removeXSSAttacks(product.long_description)}
                           className={"long-description-format"}/>
                    </div>

                    <ul className="single-info-list">
                        {
                            !product.tags == null && product.tags.length > 0 ?
                                <li>
                                    {t("label.tags")}: {product.tags.map((item, index) =>
                                    (
                                        <React.Fragment key={`single-cat-${index}`}>
                                            <strong>
                                                <ALink href={{pathname: '/shop', query: {tag: item.slug}}}
                                                       className="category">{item.name}</ALink>
                                            </strong>
                                            {index < product.tags.length - 1 ? ', ' : ''}
                                        </React.Fragment>
                                    ))
                                }
                                </li>
                                : ''
                        }
                    </ul>

                    {
                        isSticky &&
                        <div className="sticky-wrapper">
                            <div className="sticky-header desktop-sticky sticky-cart d-none d-lg-block">
                                <div className="container">
                                    <div className="sticky-img mr-4 media-with-lazy">
                                        <figure className="mb-0">
                                            <LazyLoadImage
                                                src={process.env.REACT_APP_PUBLIC_ASSET_URI + product.images[0].url}
                                                width="100%"
                                                height="auto"
                                                alt="Thumbnail"
                                            />
                                        </figure>
                                    </div>
                                    <div className="sticky-detail">
                                        <div className="sticky-product-name">
                                            <h2 className="product-title w-100 ls-0">{product.name}</h2>
                                        </div>
                                    </div>

                                    <div className="product-action">
                                        {/* eslint-disable-next-line*/}
                                        <a href="#" className={`btn btn-dark add-cart mr-2 `} title={t("label.buy")}
                                           onClick={onAddCartClick}>{t("label.buy")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="product-action">
                        {/* eslint-disable-next-line*/}
                        <a href="#" className={`btn btn-dark add-cart shopping-cart mr-2`} title={t("label.buy")}
                           onClick={onAddCartClick}>{t("label.buy")}</a>
                    </div>

                    <SingleTabOne product={product}/>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {
    showLoginModal,
    addProductToCart,
    saveCart,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailOne);