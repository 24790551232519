import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from "../../common/ALink";
import OwlCarousel from '../../features/owl-carousel';
import useTranslation from "../../../utils/hooks/useTranslation";

function SolutionSingle ( props ) {
    const {t} = useTranslation();
    const { solution } = props;
    const { content } = solution;

    return (
        <article className="post single">
            <div className={"row"}>
                <div className={"col-lg-7"}>
                    <h2 className="post-title">
                        { t(solution.title) }
                    </h2>

                    <div className={"mt-2"}>
                        {content.intro.map((section, index) => (
                            <div key={`intro-${index}`}>
                                {section.title && <h4>{section.title}</h4>}
                                <p className={"long-description-format"}>{ section.content }</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={"col-lg-5"}>
                    <figure className="post-media">
                        <LazyLoadImage
                            alt="post_image"
                            src={ process.env.REACT_APP_PUBLIC_ASSET_URI + content.intro_image.url }
                            threshold={ 500 }
                            width="100%"
                            height="auto"
                            effect="blur"
                        />
                    </figure>
                </div>
            </div>
            {content.overview_image && <div className={"row"}>
                <div className={"col-12"}>
                    <h4 className={"solution-section-header"}>
                        {t("message.solution_overview")}
                    </h4>
                    <figure className="post-media">
                        <LazyLoadImage
                            alt="post_image"
                            src={process.env.REACT_APP_PUBLIC_ASSET_URI + content.overview_image.url}
                            threshold={500}
                            width="100%"
                            height="auto"
                            effect="blur"
                        />
                    </figure>
                </div>
            </div>}
            {content.solution_details && <div className={"row"}>
                <div className={"col-12"}>
                    <h4 className={"solution-section-header"}>
                        {t("message.solution_details")}
                    </h4>
                    <div className={"row"}>
                        {content.solution_details.map((details, index) => (
                            <div className={"col-lg-4"} key={"solution-details" + index}>
                                <figure className="post-media">
                                    <LazyLoadImage
                                        alt="post_image"
                                        src={process.env.REACT_APP_PUBLIC_ASSET_URI + details.image_url}
                                        threshold={500}
                                        width="100%"
                                        height="auto"
                                        effect="blur"
                                    />
                                </figure>
                                <h5 className={"text-center"}>{details.title}</h5>
                            </div>
                        ))}
                    </div>
                </div>
            </div>}
            {content.key_technology && <div className={"row"}>
                <div className={"col-12"}>
                    <h4 className={"solution-section-header"}>
                        {t("message.key_technology")}
                    </h4>
                    <div className={"row"}>
                        {content.key_technology.map((technology, index) => (
                            <div className={`${content.key_technology.length === 1 ? 'col-lg-12' : 'col-lg-6'} d-flex flex-column justify-content-start`}
                                 key={"solution-technology" + index}>
                                <h5>{technology.title}</h5>
                                <p className={"long-description-format"}>{technology.content}</p>
                                <figure className="post-media mt-auto">
                                    <LazyLoadImage
                                        alt="post_image"
                                        src={process.env.REACT_APP_PUBLIC_ASSET_URI + technology.image_url}
                                        threshold={500}
                                        width="100%"
                                        height="auto"
                                        effect="blur"
                                    />
                                </figure>
                            </div>
                        ))}
                    </div>
                </div>
            </div>}
            {content.features && <div className={"row"}>
                <div className={"col-12"}>
                    <h4 className={"solution-section-header"}>
                        {t("message.solution_features")}
                    </h4>
                    <ul className={"bullet-point-list"}>
                        {content.features.map((feature, index) => (
                            <li key={"solution-feature" + index}>{feature}</li>
                        ))}
                    </ul>
                </div>
            </div>}
            {content.system_structure && <div className={"row"}>
                <div className={"col-12"}>
                    <h4 className={"solution-section-header"}>
                        {t("message.system_structure")}
                    </h4>
                    <figure className="post-media">
                        <LazyLoadImage
                            alt="post_image"
                            src={process.env.REACT_APP_PUBLIC_ASSET_URI + content.system_structure.image_url}
                            threshold={500}
                            width="100%"
                            height="auto"
                            effect="blur"
                        />
                    </figure>
                </div>
            </div>}
            {content.system_topology && <div className={"row"}>
                <div className={"col-12"}>
                    <h4 className={"solution-section-header"}>
                        {t("message.system_topology")}
                    </h4>
                    <figure className="post-media">
                        <LazyLoadImage
                            alt="post_image"
                            src={process.env.REACT_APP_PUBLIC_ASSET_URI + content.system_topology.image_url}
                            threshold={500}
                            width="100%"
                            height="auto"
                            effect="blur"
                        />
                    </figure>
                </div>
            </div>}
        </article>
    )
}

export default SolutionSingle;