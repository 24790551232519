import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from '../../common/ALink';
import {showLoginModal, showQuickView} from "../../../store/modal/actions";
import {connect} from "react-redux";
import useTranslation from "../../../utils/hooks/useTranslation";
import usePrice from "../../../utils/hooks/usePrice";
import {addProductToCart, saveCart} from "../../../store/cart/actions";

function ProductOne(props) {
    const { t } = useTranslation();
    const { c } = usePrice();
    const {adClass = "", product, showQuickView, showLoginModal, user, addProductToCart, saveCart} = props;

    function onAddCartClick(e) {
        e.preventDefault();

        if(!user) {
            showLoginModal();
            return;
        }

        const cartProduct = {
            id: product.id,
            quantity: 1,
            type: 'product',
            name: product.name,
            maxQuantity: product.quantity,
            price: product.price,
            images: product.images,
        }

        addProductToCart(cartProduct);
        saveCart();
    }

    function onQuickViewClick(e) {
        e.preventDefault();
        showQuickView(product);
    }

    function prepareShortDescription() {
        var length = 50;
        if (product.short_description.length > length) {
            return product.short_description.substring(0, length) + "...";
        }
        return product.short_description;
    }

    return (
        <div className={`product-default media-with-lazy ${adClass}`}>
            <figure>
                <ALink href={`/product/${product.id}`} scroll={ true }>
                    <div className="lazy-overlay"></div>

                    <LazyLoadImage
                        alt="product"
                        src={process.env.REACT_APP_PUBLIC_ASSET_URI + product.images[0]?.url}
                        threshold={500}
                        effect="black and white"
                        width="100%"
                        height="auto"
                    />
                    {
                        product.images.length >= 2 ?
                            <LazyLoadImage
                                alt="product"
                                src={process.env.REACT_APP_PUBLIC_ASSET_URI + product.images[1]?.url}
                                threshold={500}
                                effect="black and white"
                                wrapperClassName="product-image-hover"
                            />
                            : ""
                    }
                </ALink>
            </figure>

            <div className="product-details">
                <div className="category-wrap">
                    <div className="category-list">
                        {
                            product.categories ?
                                product.categories.map((item, index) => (
                                    <React.Fragment key={item.slug + '-' + index}>
                                        <ALink href={{pathname: '/shop', query: {category: item.slug}}}>
                                            {item.name}
                                        </ALink>
                                        {index < product.categories.length - 1 ? ', ' : ""}
                                    </React.Fragment>
                                )) : ""
                        }
                    </div>
                </div>


                <h3 className="product-title d-flex flex-column justify-content-center text-center">
                    <ALink href={`/product/${product.id}`} scroll={ true }>{product.name}</ALink>
                    <small>{prepareShortDescription()}</small>
                </h3>

                {product.price > 0 && <div className="price-box">
                    <span className="product-price">{c(product.price)}</span>
                </div>}

                <div className="product-action">
                    <a href="#" className="btn-icon btn-dark btn-add-cart product-type-simple" title={t("label.buy")}
                       onClick={onAddCartClick}><i
                        className="icon-shopping-cart"></i><span>{t("label.buy")}</span></a>
                    <a href="#" className="btn-quickview" title="Quick View" onClick={onQuickViewClick}><i
                        className="fas fa-external-link-alt"></i></a>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {
    showQuickView,
    showLoginModal,
    addProductToCart,
    saveCart,
}

export default connect( mapStateToProps, mapDispatchToProps )( ProductOne );
