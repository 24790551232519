import { useParams } from 'react-router-dom';
import SolutionSingle from "../../components/features/solutions/solution-single";
import SolutionSidebarOne from "../../components/partials/solutions/solution-sidebar-one";
import * as solutions from "../../data/solutions"

function SolutionDetails () {
    const params = useParams();

    const slug = params.slug.replace("-", "_");
    const solution = solutions[slug];

    return (
        <>
            <SolutionSidebarOne selectedSolution={solution.slug} />
            <div className="col-lg-8">
                <SolutionSingle solution={ solution } />
            </div>
        </>
    )
}

export default SolutionDetails;