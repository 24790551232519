import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import OwlCarousel from '../../features/owl-carousel';
import ALink from '../../common/ALink';

// Import Settigns
import { categorySlider } from '../../../utils/data/slider';
import {connect} from "react-redux";
import useTranslation from "../../../utils/hooks/useTranslation";

function CategorySection ({categories}) {
    const { t } = useTranslation();

    return (
        <div className="category-section">
            <h2 className="section-title categories-section-title heading-border border-0 ls-0">{t("message.browse_our_categories")}</h2>

            <OwlCarousel adClass="categories-slider owl-theme nav-outer show-nav-hover" options={ categorySlider }>
                {categories?.map((category) => (
                    <div className="product-category" key={`category-${category.id}`}>
                        <ALink href={ `/shop?category=${ category.id }` }>
                            <figure>
                                <LazyLoadImage
                                    alt="category"
                                    src={ process.env.REACT_APP_PUBLIC_ASSET_URI + category.image_url }
                                    width="100%"
                                    height="auto"
                                    threshold={500}
                                    effect="black and white"
                                />
                            </figure>
                            <div className="category-content">
                                <h3>{category.name}</h3>
                            </div>
                        </ALink>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    );
}

const mapStateToProps = ( state ) => {
    return {
        categories: state.categories.list
    }
}

const mapDispatchToProps = {}

export default connect( mapStateToProps, mapDispatchToProps )( CategorySection );